import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Carousel2 from "./Home/Carousel2";
import img2 from "./images/img2.jpg";
import img3 from "./images/img3.jpg";
import img4 from "./images/img4.jpg";
import img5 from "./images/img5.jpg";
import img6 from "./images/img6.jpg";
import img7 from "./images/img7.jpg";

export default class Home extends React.Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Home | TPX Doors & Windows</title>
          <meta
            name="description"
            content="Check out our high quality doors and windows from top European manufacturers that can be brought to to the Vancouver market at competetive prices."
          />
        </Helmet>
        <Carousel2 />
        <section class="my-4">
          <div class="row mx-5">
            <div class="px-4 col-12 col-md-11">
              <div class="component-content">
                <h1 className="mb-3">
                  Doors and Windows Built For How You Live
                </h1>
                <p
                  className="mb-0"
                  style={{ textAlign: "justify", fontSize: "1.1rem" }}
                >
                  Diverse range of doors and their superior finish will
                  definitely suit your both, exterior as well as interior design
                  requirements. While each of our window systems offers great
                  thermal and acoustic insulation, guaranteeing functionality
                  and years of durability. <br />
                  Our suppliers have more than 20 years of experience and they
                  are the largest and most dynamically developing companies on
                  the European market.
                  <br />
                  <br />
                  We invite you to check our offer feeling confident that you
                  will be able to find your perfect choice, something
                  exceptional among Vancouver doors and windows.
                </p>
                <p className="no-mobile">&nbsp;</p>
              </div>
            </div>
            {/* <div
              className="col-md-4 no-mobile position-relative"
              style={{
                background: `url(${familyimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div> */}
          </div>
        </section>
        <section>
          <div className="row mx-5 my-2 px-3">
            {/* <h1>Check our offer</h1> */}
            <Link to="/doors" className="col-md-6 col-12 no-decoration">
              <div className=" mt-3">
                <div className="gmt-section">
                  <p className="gmt-title">VIEW ALL DOORS</p>
                </div>
              </div>
            </Link>
            <Link to="/windows" className="col-md-6 col-12 no-decoration">
              <div className="mt-3">
                <div className="gmt-section">
                  <p className="gmt-title">VIEW ALL WINDOWS</p>
                </div>
              </div>
            </Link>
          </div>
        </section>
        <div className="row mx-5">
          <div className="col-md-4 col-6 p-2">
            <img src={img2} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
          <div className="col-md-4 col-6 p-2">
            <img src={img3} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
          <div className="col-md-4 col-6 p-2">
            <img src={img7} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
          <div className="col-md-4 col-6 p-2">
            <img src={img5} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
          <div className="col-md-4 col-6 p-2">
            <img src={img4} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
          <div className="col-md-4 col-6 p-2">
            <img src={img6} alt="" style={{ width: "100%", height: "20vw" }} />
          </div>
        </div>
        <hr className="my-5 mx-5" />
        <div className="row mx-5 pt-4">
          <div className="col-lg-4 col-12 mt-3 box-link">
            <Link to="/installation" className="no-decoration">
              <div
                className="col-12 border-box text-center"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                <div
                  className="box-image mb-2"
                  alt=""
                  style={{
                    backgroundImage:
                      "url('https://4d6gid2pcbkq1dzi5u5u3mzv-wpengine.netdna-ssl.com/wp-content/uploads/2019/05/5-problems-that-can-arise-from-poor-exterior-door-installation-1.jpg')",
                  }}
                ></div>
                <div className="px-4 pb-4">
                  INSTALLATION
                  <p style={{ fontSize: 15, fontWeight: "lighter", margin: 0 }}>
                    Let our specialist take care of doors and windows
                    installation in your house.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-12 mt-3 box-link">
            <Link to="/about" className="no-decoration">
              <div
                className="col-12 border-box text-center"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                <div
                  className="box-image mb-2"
                  alt=""
                  style={{
                    backgroundImage:
                      "url('https://media.istockphoto.com/photos/business-district-building-street-office-building-picture-id1196323722?k=6&m=1196323722&s=612x612&w=0&h=nCM884ZtuIt0EJZ9masd8dBThYE0f9PTsHJev8pFgQs=')",
                  }}
                ></div>
                <div className="px-4 pb-4">
                  ABOUT US
                  <p style={{ fontSize: 15, fontWeight: "lighter", margin: 0 }}>
                    Find out more about the benefits of working with us and
                    learn about our experience.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-12 mt-3 mb-5 box-link">
            <Link to="/contact" className="no-decoration">
              <div
                className="col-12 border-box text-center"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                <div
                  className="box-image mb-2"
                  alt=""
                  style={{
                    backgroundImage:
                      "url('https://t4.ftcdn.net/jpg/02/51/57/21/360_F_251572138_vArD0Za9zedNUGtdz5NH5cZUYSsEZ8VK.jpg')",
                  }}
                ></div>
                <div className="px-4 pb-4">
                  LET'S TALK
                  <p style={{ fontSize: 15, fontWeight: "lighter", margin: 0 }}>
                    Do you have any more questions? Contact us and we will
                    gladly dispel any doubts.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
