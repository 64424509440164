import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/hardware/bg.jpg";
import modern from "../images/doors/hardware/modern.jpg";
import next from "../images/doors/hardware/next.jpg";
import royal from "../images/doors/hardware/royal.jpg";
import elegant from "../images/doors/hardware/elegant.jpg";
import unico from "../images/doors/hardware/unico.jpg";
import topaz from "../images/doors/hardware/topaz.jpg";
import barcelona from "../images/doors/hardware/barcelona.jpg";
import luna from "../images/doors/hardware/luna.jpg";
import cube from "../images/doors/hardware/cube.jpg";
import julia from "../images/doors/hardware/julia.jpg";
import divo from "../images/doors/hardware/divo.jpg";
import prisma from "../images/doors/hardware/prisma.jpg";
import lock1 from "../images/doors/hardware/lock1.jpg";
import lock2 from "../images/doors/hardware/lock2.jpg";
import hinge1 from "../images/doors/hardware/hinge1.jpg";
import hinge2 from "../images/doors/hardware/hinge2.jpg";
import hinge3 from "../images/doors/hardware/hinge3.jpg";
import hinge4 from "../images/doors/hardware/hinge4.jpg";
import hinge5 from "../images/doors/hardware/hinge5.jpg";
import hinge6 from "../images/doors/hardware/hinge6.jpg";
import hinge7 from "../images/doors/hardware/hinge7.jpg";
import hinge8 from "../images/doors/hardware/hinge8.jpg";
import { Helmet } from "react-helmet";

// Modern, Next, Royal, Elegant, Unico, Topaz, Barcelona, Luna, Cube i Prisma
export default class Hardware extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Hardware | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet height="70" hr="1" title="Hardware" desc="" image={bg} />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            We also offer a wide selection of door handles and accessories.
            These have been designed to complement your door.
          </p>
          <div className="row pt-4 item-container">
            <p className="fw-bold text-decoration-underline">Handles:</p>
            <div className="col-md-3 col-sm-6">
              <img
                src={modern}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={next}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={royal}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={elegant}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={unico}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={topaz}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={barcelona}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={luna}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={cube}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={prisma}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={julia}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6">
              <img
                src={divo}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <p className="fw-bold pt-3">Many more models available</p>
            <hr />

            {/*  */}

            <p className="fw-bold text-decoration-underline">
              Locks and Hinges:
            </p>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={lock1}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={lock2}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge1}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge2}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge3}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge4}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge5}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge6}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge7}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
            <div className="col-md-3 col-sm-6 pt-2">
              <img
                src={hinge8}
                className="img-system"
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
