import React, { Component } from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import bg from "./images/about/bg.jpg";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const Failed = () => {
  return (
    <div className="page">
      <Helmet>
        <title>Failed | TPX Doors & Windows</title>
        <meta name="description" content="Get in touch with our specialists." />
      </Helmet>
      <HeaderDet height="50" cls="d-none" btnLink="/contact" image={bg} />

      <div className="container">
        <div className="confirm-box failed">
          <i class="fas fa-times"></i> <b>Something went wrong on our end.</b>{" "}
          Please try resending the form or contact us directly.
        </div>
      </div>
    </div>
  );
};

export default Failed;
