import React, { Component } from "react";
import { Helmet } from "react-helmet";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/commercial/bg.jpg";
import c1 from "../images/doors/commercial/commercial1.PNG";
import c2 from "../images/doors/commercial/commercial2.PNG";
import c3 from "../images/doors/commercial/commercial3.PNG";
import c4 from "../images/doors/commercial/commercial4.PNG";
import c5 from "../images/doors/commercial/commercial5.PNG";
import c6 from "../images/doors/commercial/commercial6.PNG";
import c7 from "../images/doors/commercial/commercial7.PNG";
import c8 from "../images/doors/commercial/commercial8.PNG";

export default class Commercial extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Commercial Doors | TPX Doors & Windows</title>
          <meta name="description" content="" />
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Commercial doors"
          desc=""
          image={bg}
          pos="right"
        />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            Interior entrance door from corridors or staircases to e.g. flats in
            multi-family buildings or rooms in public utility buildings. Door
            with fixed or adjustable door wooden or metal frame. The door leaf
            structure consists of a wooden rail and stile set topped with two
            HDF boards. In-fill is made of perforated chipboard, optio- nally
            solid chipboard, or special structure. The rated door leaf thickness
            is from 2 inch.
          </p>
          <ul className="facts pb-3">
            <p className="fw-bold">Facts and Standard equipment:</p>
            <li>Acoustic insulation: Class Rw from 27 up to 37dB</li>
            <li>Burglary resistance : Class RC2</li>
            <li>Door with wooden frame</li>
            <li>Pivot hinges adjustable in 3 planes – 3 pcs.</li>
            <li>Strip type lock with four-point bolt</li>
            <li>
              Striker plates, adjustable, reinforced - anti-burglary bolts – 3
              pcs
            </li>
            <li>
              Oak threshold in the colour of natural oak, black, grey, brown
              with aluminium strip protecting against excessive exploitation
            </li>
            <li>Oak threshold</li>
          </ul>
        </div>
        <div className="row mx-5 mb-5">
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c1} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c2} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c3} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c4} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c5} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c6} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c7} alt="" className="d-block m-auto h-100" />
          </div>
          <div className="col-md-3 col-6 py-3" style={{ height: "370px" }}>
            <img src={c8} alt="" className="d-block m-auto h-100" />
          </div>
        </div>
      </div>
    );
  }
}
