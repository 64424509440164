import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/steel/bg.jpg";
import argos from "../images/doors/steel/argos.jpg";
import eris from "../images/doors/steel/eris.jpg";
import inox from "../images/doors/steel/inox.jpg";
import logic from "../images/doors/steel/logic.jpg";
import logos from "../images/doors/steel/logos.jpg";
import orbital from "../images/doors/steel/orbital.jpg";
import { Helmet } from "react-helmet";

export default class EntrySteel extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Steel Doors | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet height="70" hr="1" title="Steel Doors" desc="" image={bg} />
        <div className="my-3 mx-3 item-details">
          <p className="pb-3" style={{ fontSize: "1.1rem" }}>
            Steel doors are widely used in houses, apartments and in places
            where door operating conditions require a higher resistance to
            loads, mechanical damages and corrosion. <br />
            The use of metal materials does not preclude the creation of
            interesting and structurally advanced products. Well integrated
            doors may constitute an architectural detail that bonds the whole
            interior. The precision of performance combined with a high-quality
            powder coating in any color, enables manufacturing of a product
            adapted to individual needs.
          </p>
          <div className="row pt-4 item-container">
            <div className="col-md-4 p-2">
              <img src={argos} className="w-100" alt="" />
            </div>
            <div className="col-md-4 p-2">
              <img src={eris} className="w-100" alt="" />
            </div>
            <div className="col-md-4 p-2">
              <img src={inox} className="w-100" alt="" />
            </div>
            <div className="col-md-4 p-2">
              <img src={logos} className="w-100" alt="" />
            </div>
            <div className="col-md-4 p-2">
              <img src={logic} className="w-100" alt="" />
            </div>
            <div className="col-md-4 p-2">
              <img src={orbital} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
