import React from "react";
import { Link } from "react-router-dom";

const CarouselItem = ({
  title,
  desc,
  btnText,
  btnLink,
  img,
  imgPos,
  width,
}) => {
  return (
    <>
      <div
        className="vertical-text header-text header-text-inside"
        style={{
          zIndex: 10,
          left: "4vw",
          width: width,
          top: "450px",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>{title}</h2>
        <p style={{ maxWidth: "100%" }}>{desc}</p>
        <Link to={btnLink} transition="glide-right">
          <button
            className="btn btn-main px-4"
            style={{ fontWeight: "bold", float: "right" }}
          >
            {btnText}
          </button>
        </Link>
      </div>
      <div className="header-text-mobile mx-2 pt-3 position-absolute">
        <span style={{ fontWeight: "bold", fontSize: 30 }} className="mb-2">
          {title}
        </span>
        <br />
        <span style={{ maxWidth: "80%" }}>{desc}</span>
        <br />
        <Link to={btnLink}>
          <button className="btn btn-main px-4" style={{ fontWeight: "bold" }}>
            {btnText}
          </button>
        </Link>
        <hr />
      </div>
      <img
        src={img}
        class="d-block w-100"
        alt="..."
        style={{ objectPosition: `${imgPos}` }}
      />
    </>
  );
};

export default CarouselItem;
