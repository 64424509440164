import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/residential/rebated_bg.jpg";
import info1 from "../images/doors/residential/rebated_info.jpg";
import info2 from "../images/doors/residential/non-rebated_info.jpg";
import a0 from "../images/doors/residential/a0.jpg";
import impress from "../images/doors/residential/non-rebated.jpg";
import { Helmet } from "react-helmet";

export default class Rebated extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Rebated and Non-rebated | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Rebated and Non-rebated"
          desc=""
          image={bg}
        />
        <div className="my-3 mx-3 item-details">
          <div className="row pt-4 item-container">
            <div className="col-md-4">
              <img src={a0} alt="" className="product-img" />
            </div>
            <div className="col-md-8">
              <p className="fw-bold text-decoration-underline">Rebated Doors</p>
              <p>
                A standard feature of our collections. The door leaf features a
                rebate lip which overlaps the frame upon closing. The exposed
                hinges and decorative details of the doors strike a good tone in
                traditionally designed rooms.
              </p>
              <img
                src={info1}
                className="pb-3"
                alt=""
                style={{ width: "100px", height: "auto" }}
              />
            </div>
            <hr />
            <div className="col-md-4">
              <img src={impress} alt="" className="product-img" />
            </div>
            <div className="col-md-8">
              <p className="fw-bold text-decoration-underline">
                Non-rebated Doors
              </p>
              <p>
                The straight leaf edge and the door frame form a uniform plane.
                Visually, this form looks more contemporary and devoid of
                obsolete details, which is perfect for minimalist fitouts.
              </p>
              <img
                src={info2}
                alt=""
                style={{ width: "100px", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
