import Navbar from "./components/Navbar";
import Home from "./components/Home";
import WindowsHome from "./components/WindowsHome";
import VinylWindows from "./components/Windows/VinylWindows";
import AluminumWindows from "./components/Windows/AluminumWindows";
import EntryWooden from "./components/Doors/EntryWooden";
import EntrySteel from "./components/Doors/EntrySteel";
import InteriorResidential from "./components/Doors/InteriorResidential";
import Rebated from "./components/Doors/Rebated";
import Veneer from "./components/Doors/Veneer";
import DoubleDoors from "./components/Doors/DoubleDoors";
import Custom from "./components/Doors/Custom";
import Hardware from "./components/Doors/Hardware";
import Frames from "./components/Doors/Frames";
import AboutUs from "./components/AboutUs";
import NotFound from "./components/NotFound";
import DrzwiHome from "./components/DrzwiHome";
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./components/Contact";
import Success from "./components/Success";
import Failed from "./components/Failed";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useState } from "react";
import WoodenWindows from "./components/Windows/WoodenWindows";
import Commercial from "./components/Doors/Commercial";
import Installation from "./components/Installation";

function App() {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div className="page">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={5000}
                classNames="fade"
              >
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/doors/" exact component={DrzwiHome} />
                  <Route path="/doors/wood" exact component={EntryWooden} />
                  <Route path="/doors/steel" exact component={EntrySteel} />
                  {/*  */}
                  <Route
                    path="/doors/residential"
                    exact
                    component={InteriorResidential}
                  />
                  <Route
                    path="/doors/residential/rebated-and-non-rebated"
                    component={Rebated}
                  />
                  <Route
                    path="/doors/residential/natural-veneer"
                    component={Veneer}
                  />
                  <Route
                    path="/doors/residential/double-doors"
                    component={DoubleDoors}
                  />
                  <Route
                    path="/doors/residential/custom-made"
                    component={Custom}
                  />
                  {/*  */}
                  <Route
                    path="/doors/commercial"
                    exact
                    component={Commercial}
                  />
                  <Route path="/doors/hardware" exact component={Hardware} />
                  <Route path="/doors/frames" exact component={Frames} />
                  <Route path="/about/" exact component={AboutUs} />
                  <Route path="/windows/" exact component={WindowsHome} />
                  <Route path="/windows/vinyl" exact component={VinylWindows} />
                  <Route
                    path="/windows/wooden"
                    exact
                    component={WoodenWindows}
                  />
                  <Route
                    path="/windows/aluminum"
                    exact
                    component={AluminumWindows}
                  />
                  <Route path="/contact" exact component={Contact} />
                  <Route path="/contact/success" exact component={Success} />
                  <Route path="/contact/failed" exact component={Failed} />
                  <Route path="/installation" exact component={Installation} />
                  <Route component={NotFound}></Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </div>
  );
}

export default App;
