import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/wooden/bg.jpg";
import fiord from "../images/doors/wooden/fiord28.jpg";
import future from "../images/doors/wooden/future25.jpg";
import glass from "../images/doors/wooden/glass61.jpg";
import loft from "../images/doors/wooden/loft02.jpg";
import rp from "../images/doors/wooden/rp29.jpg";
import rp2 from "../images/doors/wooden/rp41.jpg";
import d6 from "../images/doors/wooden/d6.jpeg";
import d7 from "../images/doors/wooden/d7.jpeg";
import d8 from "../images/doors/wooden/d8.jpeg";
import d9 from "../images/doors/wooden/d9.jpeg";
import wood1 from "../images/doors/wooden/wood1.jpg";
import wood2 from "../images/doors/wooden/wood2.jpg";
import wood3 from "../images/doors/wooden/wood3.jpg";
import wood4 from "../images/doors/wooden/wood4.jpg";
import wood5 from "../images/doors/wooden/wood5.jpg";
import { Helmet } from "react-helmet";

export default class EntryWooden extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Wood Doors | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet height="70" hr="1" title="Wood Doors" desc="" image={bg} />
        <div className="my-3 mx-3 item-details">
          <p className="pb-3" style={{ fontSize: "1.1rem" }}>
            A real house needs real doors. With our collections you can give
            your home a unique style right from the entrance door. Specially
            selected, hand-worked wood, the possibility to create an original
            portal with panels, transoms, or stained-glass panes, a perfect
            finish... This is a real style wooden door.
            <br />
            The unique heat transfer coefficient. Thermal insulation (Ud) at the
            level from 0.58 W / m2K, and high burglar resistance class (RC2) as
            standard, will provide you with the comfort of life – yours and your
            family.
          </p>
          <p className="pb-3">
            Some selected models below, many more available depending on your
            individual needs and preferences.
          </p>
          <div className="d-flex flex-wrap justify-content-center pt-4 item-container">
            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={fiord}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={rp}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={glass}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={rp2}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={future}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={d6}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={d7}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={loft}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={d8}
                alt=""
                style={{
                  width: "100%",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={d9}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>

            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={wood1}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={wood2}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
            <div className="p-4" style={{ width: "275px" }}>
              <img
                src={wood3}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
            <div className=" p-4" style={{ width: "275px" }}>
              <img
                src={wood4}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
            <div className=" p-4" style={{ width: "275px" }}>
              <img
                src={wood5}
                alt=""
                style={{
                  width: "auto",
                  height: "235px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
