import React from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import bg from "./images/installation/bg.jpg";
import { Helmet } from "react-helmet";

const Installation = () => {
  return (
    <div className="page">
      <Helmet>
        <title>Contact Us | TPX Doors & Windows</title>
        <meta name="description" content="Get in touch with our specialists." />
      </Helmet>

      <HeaderDet
        height="50"
        title="Installation"
        btnLink="/contact"
        image={bg}
      />

      <div className="container">
        <h4 className="mb-3">
          High quality products also require a perfect service.
        </h4>
        <p>
          We know how to secure and properly place doors and windows in a
          responsible manner. A careful, well done installation not only
          protects from long term damage but also allows them to do what they
          were designed to do: insulate the house from cold ( or hot) outside.
        </p>
        <p>
          All doors and windows will be inspected after installation to ensure
          all your requirements are met.
        </p>
        <Link to="/contact" className="no-decoration">
          <button className="btn btn-main shadow-none">CONTACT US</button>
        </Link>
      </div>
    </div>
  );
};

export default Installation;
