import React, { Component } from "react";
import { Helmet } from "react-helmet";
import HeaderDet from "../HeaderDet";
import bg from "../images/windows/wooden/bg.jpg";
import profile1 from "../images/windows/wooden/profile1.jpg";
import profile2 from "../images/windows/wooden/profile2.jpg";
import profile3 from "../images/windows/wooden/profile3.jpg";
import profile4 from "../images/windows/wooden/profile4.jpg";

export default class WoodenWindows extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Wood Windows | TPX Doors & Windows</title>
          <meta
            name="description"
            content="Our wood windows are sustainable from many perspectives. Not only
            are they more environmentally friendly, they are durable over time
            and adapted for energy-efficient installations. They also have
            furniture quality in terms of surface and detailed finish."
          />
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Wood windows"
          desc=""
          image={bg}
          cls="mb-4"
        />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            Our wood windows are sustainable from many perspectives. Not only
            are they more environmentally friendly, they are durable over time
            and adapted for energy-efficient installations. They also have
            furniture quality in terms of surface and detailed finish.
          </p>
          <div className="row pt-4 item-container">
            <div className="col-md-3 col-10 pt-3">
              <img src={profile1} alt="" className="d-block w-75 img-system" />
            </div>

            <div className="col-md-3 col-10 pt-3">
              <img src={profile2} alt="" className="d-block w-75 img-system" />
            </div>
            <div className="col-md-3 col-10 pt-3">
              <img src={profile3} alt="" className="d-block w-75 img-system" />
            </div>
            <div className="col-md-3 col-10 pt-3">
              <img src={profile4} alt="" className="d-block w-75 img-system" />
            </div>

            <ul className="facts">
              <br />
              <p className="fw-bold mt-3">Facts:</p>
              <li>Thermal transmittance*: 0,80 W/(m2K) </li>
              <li>Water tightness: E900</li>
              <li>Air permeability: 4</li>
              <li>Resistance to wind load: C3</li>
              <li>Acoustic properties: 34 (-1;-5) dB</li>
              <br />
              <p className="fw-bold">Additional Accessories: </p>
              <li>Glazing units </li>
              <li>Window bars and thermally insulated window sills </li>
              <li>Fitting accessories </li>
              <li>Decorations for wood mullion mouldings </li>
              <li>Air inlets</li>
              <li>Muntins</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
