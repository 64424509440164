import React from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import bg from "./images/windows/bg.jpg";
import wood from "./images/windows/wood_cat.jpg";
import alu from "./images/windows/alu_cat.jpg";
import vinyl from "./images/windows/vinyl_cat.jpg";
import { Helmet } from "react-helmet";

export default class DrzwiHome extends React.Component {
  componentDidUpdate() {
    $(function () {
      $("#w-cat-vinyl").hover(function () {
        $("#cat-image-container-windows").css(
          "background-image",
          `url(${vinyl})`
        );
      });
      $("#w-cat-wooden").hover(function () {
        $("#cat-image-container-windows").css(
          "background-image",
          `url(${wood})`
        );
      });
      $("#w-cat-aluminum").hover(function () {
        $("#cat-image-container-windows").css(
          "background-image",
          `url(${alu})`
        );
      });
    });
  }
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Windows | TPX Doors & Windows</title>
          <meta
            name="description"
            content="Let the sun in the room. Chose the type of window frame from the following and check what suits you best."
          />
        </Helmet>
        <HeaderDet
          height="50"
          cls="d-none"
          btnLink="/contact"
          // image="https://images.unsplash.com/photo-1589535182022-02d50c9e6ee6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
          image={bg}
        />

        <div className="container">
          <div className="row cat-select my-5">
            <div className="col-md-8">
              <div className="d-flex flex-wrap me-4">
                <div className="w-100">
                  <Link to="/windows/vinyl" className="no-decoration">
                    <p className="cat-option" id="w-cat-vinyl">
                      VINYL WINDOWS
                    </p>
                  </Link>
                </div>
                <div className="w-100">
                  <Link to="/windows/wooden" className="no-decoration">
                    <p className="cat-option" id="w-cat-wooden">
                      WOODEN WINDOWS
                    </p>
                  </Link>
                </div>

                <div className="w-100">
                  <Link to="/windows/aluminum" className="no-decoration">
                    <p className="cat-option" id="w-cat-aluminum">
                      ALUMINUM WINDOWS
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4" id="cat-image-container-windows"></div>
          </div>
        </div>

        {/*  */}
      </div>
    );
  }
}
