import React, { Component } from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import bg from "./images/about/bg.jpg";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const Success = () => {
  // this.sendEmail = this.sendEmail.bind(this);
  const API_PATH = "../email.php";

  // const onSubmit = (data, e) => {
  //   // console.log(data);
  //   alert(
  //     "Name: " +
  //       data["firstName"] +
  //       " " +
  //       data["lastName"] +
  //       "\r\n" +
  //       "E-mail: " +
  //       data["email"] +
  //       "\r\n" +
  //       "Subject: " +
  //       data["subject"] +
  //       "\r\n" +
  //       "Message: " +
  //       data["message"]
  //   );

  function fileValidation(e) {
    var fileInput = document.getElementById("file");

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type. Try to use supported file formats.");
      fileInput.value = "";
      return false;
    } else {
      // Image preview
      if (fileInput.files && fileInput.files[0]) {
        var p = fileInput.files[0];
        if (p.size > 3000000) {
          alert("File too large.Try to use a photo smaller than 3MB");
          fileInput.value = "";
          return false;
        }
      }
    }
  }

  function sendEmail(e) {
    e.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: {
        "content-type": "application/json",
      },
    });
    console.log("sent");
    // emailjs
    //   .sendForm(
    //     "service_640ig7s",
    //     "template_0mkllqq",
    //     e.target,
    //     "user_55SbYmDkUybse7D0g5L0w"
    //   )
    //   .then(
    //     (result) => {
    //       alert("Your message has been sent. Thank you for contacting us!");
    //       window.location.reload();
    //     },
    //     (error) => {
    //       alert(
    //         "There was an internal error and we were unable to send your message. Please use the contact details below to contact us manually."
    //       );
    //     }
    //   );
  }

  // function send() {
  //   alert("Your message has been sent. Thank you for contacting us!");
  //   window.location.reload();
  // }

  // console.log(watch("firstName"));
  return (
    <div className="page">
      <Helmet>
        <title>Failed | TPX Doors & Windows</title>
        <meta name="description" content="Get in touch with our specialists." />
      </Helmet>
      <HeaderDet height="50" cls="d-none" btnLink="/contact" image={bg} />

      <div className="container">
        <div className="confirm-box success">
          <i class="fas fa-check"></i> <b>Thank you for contacting us.</b> We
          will try to answer your message as soon as possible.
        </div>
      </div>
    </div>
  );
};

export default Success;
