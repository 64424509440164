import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import { Link } from "react-router-dom";
import bg from "../images/doors/residential/bg.jpg";
import d1 from "../images/doors/residential/res1.PNG";
import d2 from "../images/doors/residential/res2.PNG";
import d3 from "../images/doors/residential/res3.PNG";
import d4 from "../images/doors/residential/res4.PNG";
import d5 from "../images/doors/residential/res5.PNG";
import d6 from "../images/doors/residential/res6.PNG";
import d7 from "../images/doors/residential/res7.PNG";
import d8 from "../images/doors/residential/res8.PNG";
import d9 from "../images/doors/residential/res9.PNG";
import d10 from "../images/doors/residential/res10.PNG";
import d11 from "../images/doors/residential/1.PNG";
import d12 from "../images/doors/residential/2.PNG";
import d13 from "../images/doors/residential/3.PNG";
import d14 from "../images/doors/residential/4.PNG";
import d15 from "../images/doors/residential/5.PNG";
import { Helmet } from "react-helmet";

export default class InteriorResidential extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Residential | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Residential"
          desc=""
          image={bg}
          pos="left"
        />
        <div className="my-3 mx-3 item-details">
          <p className="" style={{ fontSize: "1.1rem" }}>
            The variety of door patterns, structures and accessories will make a
            new look at your interior from a completely new perspective. We also
            give you a great opportunity to personalize the door. It means the
            doors you select are your own from the beginning to the end. <br />
            Unlike most models on the local market our doors are factory painted
            or veneered and come ready for installation.
          </p>
          <p className="pt-3">
            Some selected models below, many more available depending on your
            individual needs and preferences.
          </p>
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-wrap justify-content-center pt-4 item-container">
                <div className=" p-2" style={{ width: "275px" }}>
                  <img
                    src={d1}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>

                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d2}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>

                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d3}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>

                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d4}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>

                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d5}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d6}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d7}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d8}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d9}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d10}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d11}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d12}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d13}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d14}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
                <div className="p-2" style={{ width: "275px" }}>
                  <img
                    src={d15}
                    alt=""
                    style={{
                      width: "auto",
                      height: "235px",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 order-first order-lg-last">
              <div className="row pt-4 item-container">
                <div className="col-md-2 col-0"></div>
                <div className="col-md-8">
                  <Link
                    to="/doors/residential/rebated-and-non-rebated"
                    className="no-decoration"
                  >
                    <p className="cat-option fw-bold" id="d-cat-steel">
                      REBATED AND NON-REBATED DOORS
                    </p>
                  </Link>
                  <Link
                    to="/doors/residential/natural-veneer"
                    className="no-decoration"
                  >
                    <p className="cat-option fw-bold" id="d-cat-steel">
                      NATURAL VENEER
                    </p>
                  </Link>
                  <Link
                    to="/doors/residential/double-doors"
                    className="no-decoration"
                  >
                    <p className="cat-option fw-bold" id="d-cat-steel">
                      DOUBLE DOORS, SLIDING AND FOLDING SYSTEMS
                    </p>
                  </Link>
                  <Link
                    to="/doors/residential/custom-made"
                    className="no-decoration"
                  >
                    <p className="cat-option fw-bold" id="d-cat-steel">
                      CUSTOM MADE
                    </p>
                  </Link>
                  <div className="col-md-2 col-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
