import React from "react";
import CarouselItem from "./CarouselItem";
import carou2_alt from "../images/carousel/carou2_alt.jpg";
import carou1 from "../images/carousel/carou1.jpg";
import carou3 from "../images/carousel/carou3.jpg";
import carou4 from "../images/carousel/carou4.jpg";
import carou5 from "../images/carousel/carou5.jpg";

const Carousel2 = () => {
  return (
    <div
      id="carouselMain"
      class="carousel slide carousel-fade position-relative user-select-none"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselMain"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMain"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMain"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMain"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMain"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <CarouselItem
            title="TPX Doors&Windows"
            desc="Check out our high quality doors and windows from top European manufacturers that can be brought to the Vancouver market at competetive prices."
            btnText="ABOUT US"
            btnLink="/about"
            img={carou1}
            imgPos="right"
            width="500px"
          />
        </div>
        <div class="carousel-item">
          <CarouselItem
            title="Entry Doors"
            desc=""
            btnText="LEARN MORE"
            btnLink="/doors/wood"
            // img={carou1}
            img={carou2_alt}
            imgPos="center"
          />
        </div>
        <div class="carousel-item">
          <CarouselItem
            title="Residential Doors"
            desc=""
            btnText="LEARN MORE"
            btnLink="/doors/residential"
            img={carou3}
            imgPos="right bottom"
          />
        </div>
        <div class="carousel-item">
          <CarouselItem
            title="Commercial Doors"
            desc=""
            btnText="LEARN MORE"
            btnLink="/doors/commercial"
            img={carou4}
            imgPos="right"
          />
        </div>
        <div class="carousel-item">
          <CarouselItem
            title="Windows"
            desc=""
            btnText="LEARN MORE"
            btnLink="/windows"
            img={carou5}
            imgPos="0px 0px"
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselMain"
        data-bs-slide="prev"
        style={{ opacity: 0 }}
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselMain"
        data-bs-slide="next"
        style={{ opacity: 1 }}
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel2;
