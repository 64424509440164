import React from "react";
import { Link } from "react-router-dom";

const HeaderDet = ({
  title,
  desc,
  image,
  btnText,
  btnLink,
  height,
  hr,
  pos,
  cls,
  cls2,
}) => {
  return (
    <div>
      <header
        className={`header-main mb-4`}
        style={
          pos
            ? {
                minHeight: `${height}vh`,
                backgroundImage: `url(${image})`,
                backgroundPosition: `${pos}`,
              }
            : {
                minHeight: `${height}vh`,
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
              }
        }
      >
        <div className="container">
          {/* <nav
            aria-label="breadcrumb"
            style={{ "--bs-breadcrumb-divider": "''" }}
            className="py-5"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/#" className="no-decoration">
                  <p className="m-0 breadcrumb-link p-2">HOME</p>
                </Link>
              </li>
              <li
                className="breadcrumb-item active p-2"
                aria-current="page"
                style={{ color: "white" }}
              >
                DOORS
              </li>
            </ol>
          </nav> */}
          <div
            className={`vertical-text header-text header-text-inside ${cls}`}
          >
            <h2 style={{ fontWeight: "bold" }}>{title}</h2>
            <p style={{ maxWidth: "80%" }}>{desc}</p>
            <Link to={btnLink} transition="glide-right">
              {btnText ? (
                <button
                  className="btn btn-main px-4"
                  style={{ fontWeight: "bold" }}
                >
                  {btnText}
                </button>
              ) : null}
            </Link>
          </div>
        </div>
      </header>
      <div className={`header-text-mobile mx-2 pt-3 ${cls}`}>
        <span style={{ fontWeight: "bold", fontSize: 30 }} className="mb-2">
          {title}
        </span>
        <br />
        <span style={{ maxWidth: "80%" }}>{desc}</span>
        <br />
        <Link to={btnLink}>
          {btnText ? (
            <button
              className="btn btn-main px-4"
              style={{ fontWeight: "bold" }}
            >
              {btnText}
            </button>
          ) : null}
        </Link>
        {hr && <hr />}
      </div>
    </div>
  );
};

export default HeaderDet;
