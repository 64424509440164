import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg_alt from "../images/windows/vinyl/vinyl-bg_alt.jpg";
import profile1 from "../images/windows/vinyl/profile1.jpg";
import profile2 from "../images/windows/vinyl/profile2.jpg";
import profile3 from "../images/windows/vinyl/profile3.jpg";
import { Helmet } from "react-helmet";

export default class VinylWindows extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Vinyl Windows | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Vinyl windows"
          desc=""
          image={bg_alt}
        />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            The profile system prevents thermal bridges and cuts energy costs
            substantially. <br />
            The intelligent structural design of the profile, featuring large
            steel reinforcements, ensures the excellent stability and reliable
            functionality of the uPVC windows.
          </p>
          <div className="row pt-4">
            <div className="col-md-4 col-12 pt-3">
              <img src={profile1} alt="" className="d-block w-75 img-system" />
              <div>
                <p className="img-cap">Recessed look</p>
              </div>
            </div>

            <div className="col-md-4 col-12 pt-3">
              <img src={profile2} alt="" className="d-block w-75 img-system" />
              <div>
                <p className="img-cap">Semi recessed look with round sash</p>
              </div>
            </div>
            <div className="col-md-4 col-12 pt-3">
              <img src={profile3} alt="" className="d-block w-75 img-system" />
              <div>
                <p className="img-cap">Semi recessed look with angled sash</p>
              </div>
            </div>
          </div>
          <div className="container py-3">
            <ul className="facts">
              <p className="fw-bold" style={{ fontSize: "1.1rem" }}>
                Facts:
              </p>
              <li>5-chamber profile </li>
              <li>Stopper gasket </li>
              <li>Up to Uw = 0.73 W/(m²K)</li>
              <li>Sound insulation: up to 46 dB</li>
              <li>Burglary resistance class 2 </li>
              <li>
                Areas of application: Side and bottom hung windows, tilt and
                turn windows, side entrance doors, folding and parallel tilt and
                slide doors{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
