import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/residential/custom_bg.jpg";
import custom1 from "../images/doors/residential/custom1.jpg";
import { Helmet } from "react-helmet";

export default class Custom extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Custom Made | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Custom made"
          desc=""
          image={bg}
          pos="right"
        />
        <div className="my-3 mx-3 item-details">
          <p className="" style={{ fontSize: "1.1rem" }}>
            Doors can be ordered in non-standard width or height options to
            match the door opening you need to fill. Taller door leafs can also
            be ordered with extra hinges and with the door lock repositioned.
          </p>
          <div className="row pt-4 item-container">
            <div className="col-md-6">
              <img
                src={custom1}
                alt=""
                className="img-system"
                style={{ height: "35vw" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
