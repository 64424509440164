import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/windows/aluminum/bg.jpg";
import profile1 from "../images/windows/aluminum/profile1.jpg";
import profile2 from "../images/windows/aluminum/profile2.jpg";
import profile3 from "../images/windows/aluminum/profile3.jpg";
import { Helmet } from "react-helmet";
export default class AluminumWindows extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Aluminum Windows | TPX Doors & Windows</title>
          <meta
            name="description"
            content="The aluminium systems make it possible to build all kinds of windows
            and doors in line with the intended use and detailed demands in
            terms of functionality, aesthetics, thermal insulation, burglar
            resistance, smoke integrity and fire resistance."
          />
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Aluminum windows"
          desc=""
          image={bg}
        />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            The aluminium systems make it possible to build all kinds of windows
            and doors in line with the intended use and detailed demands in
            terms of functionality, aesthetics, thermal insulation, burglar
            resistance, smoke integrity and fire resistance. <br />
            They include aluminium structures without thermal insulation but
            there is also a range of solutions based on thermally isolated
            profiles.
          </p>
          <div className="row pt-4 item-container">
            <div className="col-md-3 col-8 pt-3">
              <img src={profile1} alt="" className="d-block w-100 img-system" />
            </div>
            <div className="col-md-9 pt-3">
              <p className="fw-bold">Window-Door system</p>
              <ul className="facts">
                <li>Air Permeability: class 4</li>
                <li>Watertightness: class E 1500 Pa</li>
                <li>Thermal insulation window (Uf) from 0,57 W/(m2K)</li>
                <li>Resistance to wind load: Class C5</li>
              </ul>
            </div>
            <div className="col-md-3 col-8 pt-3">
              <img src={profile2} alt="" className="d-block w-100 img-system" />
            </div>
            <div className="col-md-9 pt-3">
              <p className="fw-bold">Window-Door system</p>
              <ul className="facts">
                <li>Air Permeability: class 4</li>
                <li>Watertightness: class E 1200 Pa</li>
                <li>Thermal insulation (Uf) from 1,0 W/(m2K)</li>
                <li>Resistance to wind load: Class C5</li>
              </ul>
            </div>
            <div className="col-md-3 col-8 pt-3">
              <img src={profile3} alt="" className="d-block w-100 img-system" />
            </div>
            <div className="col-md-9 pt-3">
              <p className="fw-bold">Lift and slide balcony door</p>
              <ul className="facts">
                <li>Air Permeability: class 4</li>
                <li>Watertightness: class 9A </li>
                <li>Thermal insulation (Uf) from 0,84 W/(m2K)</li>
                <li>Resistance to wind load: Class C5</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
