import React from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Helmet } from "react-helmet";
import bg from "./images/doors/bg.jpg";
import dd1 from "./images/doors/dd1.jpg";
import dd2 from "./images/doors/dd2.jpg";
import dd3 from "./images/doors/dd3.jpg";
import dd4 from "./images/doors/dd4.jpg";

export default class DrzwiHome extends React.Component {
  componentDidUpdate() {
    $(function () {
      $("#d-cat-residential").hover(function () {
        $("#cat-image-container-doors").css("background-image", `url(${dd1})`);
      });
      $("#d-cat-commercial").hover(function () {
        $("#cat-image-container-doors").css("background-image", `url(${dd2})`);
      });
      $("#d-cat-wood").hover(function () {
        $("#cat-image-container-doors").css("background-image", `url(${dd3})`);
      });
      $("#d-cat-steel").hover(function () {
        $("#cat-image-container-doors").css("background-image", `url(${dd4})`);
      });
    });
  }
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Doors | TPX Doors & Windows</title>
          <meta
            name="description"
            content="Unlike most of the doors available on the market, our internal
            doors come as a finished product, ready for installation."
          />
        </Helmet>
        <HeaderDet cls="d-none" height="50" image={bg} />

        <div className="container">
          <div className="row cat-select my-5">
            <div className="col-md-8">
              <h3 className="fw-bold">INTERIOR DOORS</h3>
              {/* <h5 style={{ fontSize: "0.8rem" }}>
                Unlike most of the doors available on the market, our internal
                doors come as a finished product, ready for installation. They
                are already painted, laminated or veneered therefore no
                additional painting required.
              </h5> */}
              <div className="row me-4">
                <div className="col-md-6 col-12">
                  <Link to="/doors/residential" className="no-decoration">
                    <p className="cat-option" id="d-cat-residential">
                      RESIDENTIAL
                    </p>
                  </Link>
                </div>
                <div className="col-md-6 col-12">
                  <Link to="/doors/commercial" className="no-decoration">
                    <p className="cat-option" id="d-cat-commercial">
                      COMMERCIAL
                    </p>
                  </Link>
                </div>
              </div>

              <h3 className="fw-bold mt-3">ENTRY DOORS</h3>
              <div className="row me-4">
                <div className="col-md-6 col-12">
                  <Link to="/doors/wood" className="no-decoration">
                    <p className="cat-option " id="d-cat-wood">
                      WOOD
                    </p>
                  </Link>
                </div>

                <div className="col-md-6 col-12">
                  <Link to="/doors/steel" className="no-decoration">
                    <p className="cat-option " id="d-cat-steel">
                      STEEL
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4" id="cat-image-container-doors"></div>
          </div>
        </div>

        {/*  */}
        {/* <div className="contact-space py-5 my-4 mx-3">
          <h2 style={{ textAlign: "center" }}>
            Can't find what you're looking for?
          </h2>
          <div className="d-flex flex-wrap justify-content-center mt-3">
            <div className="m-2 gridbox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "50" }}
                fill="#00ffaa"
                viewBox="0 0 24 24"
                stroke="#000"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>{" "}
              Find our place
            </div>
            <div className="m-2 gridbox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "50" }}
                fill="#00ffaa"
                viewBox="0 0 24 24"
                stroke="#000"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>{" "}
              Contact us
              <div className=""></div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
