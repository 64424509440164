import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import logosvg from "./images/logo.svg";

export default class Navbar extends Component {
  componentDidUpdate() {
    $(function () {
      $(document).scroll(function () {
        var $nav = $("#mainNav");
        $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
        var $logo = $("#navLogo");
        $logo.toggleClass("dark", $(this).scrollTop() > $nav.height());
      });
    });
  }
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top"
        id="mainNav"
      >
        <div className="container py-3">
          <Link to="/" className="no-decoration ">
            {/* <p className="navbar-brand m-0">TPX</p> */}
            <div className="navbar-brand m-0 p-0">
              <img
                src={logosvg}
                alt=""
                width="auto"
                height="72"
                id="navLogo"
              ></img>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* Link 1 */}
              <li className="nav-item">
                <Link to="/" className="no-decoration">
                  <p
                    className="nav-link m-0"
                    role="button"
                    aria-expanded="false"
                  >
                    Home
                  </p>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <p
                  className="nav-link  dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Interior Doors
                </p>
                <ul className="dropdown-menu">
                  <Link to="/doors/residential" className="no-decoration">
                    <li>
                      <p className="dropdown-item"> Residential</p>
                    </li>
                  </Link>
                  <Link to="/doors/commercial" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Commercial</p>
                    </li>
                  </Link>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Link to="/doors/frames" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Frames</p>
                    </li>
                  </Link>
                  <Link to="/doors/hardware" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Hardware</p>
                    </li>
                  </Link>
                </ul>
              </li>
              {/* Link 2 */}
              <li className="nav-item dropdown">
                <p
                  className="nav-link  dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Entry Doors
                </p>
                <ul className="dropdown-menu">
                  <Link to="/doors/wood" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Wood doors</p>
                    </li>
                  </Link>
                  <Link to="/doors/steel" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Steel doors</p>
                    </li>
                  </Link>
                </ul>
              </li>
              {/* Link 3 */}
              <li className="nav-item dropdown">
                <p
                  className="nav-link  dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Windows
                </p>
                <ul className="dropdown-menu">
                  <Link to="/windows/vinyl" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Vinyl windows</p>
                    </li>
                  </Link>
                  <Link to="/windows/wooden" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Wood windows</p>
                    </li>
                  </Link>
                  <Link to="/windows/aluminum" className="no-decoration">
                    <li>
                      <p className="dropdown-item">Aluminum windows</p>
                    </li>
                  </Link>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/installation" className="no-decoration">
                  <p
                    className="nav-link m-0"
                    role="button"
                    aria-expanded="false"
                  >
                    Installation
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="no-decoration">
                  <p
                    className="nav-link m-0"
                    role="button"
                    aria-expanded="false"
                  >
                    About us
                  </p>
                </Link>
              </li>
            </ul>
            <Link to="/contact" className="no-decoration">
              <button className="btn btn-main shadow-none">CONTACT US</button>
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}
