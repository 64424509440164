import React, { Component } from "react";
import { Helmet } from "react-helmet";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/frames/bgalt2.jpg";
import frame1 from "../images/doors/frames/frame1.PNG";
import frame1a from "../images/doors/frames/frame1a.PNG";
import frame1b from "../images/doors/frames/frame1b.PNG";
import frame1c from "../images/doors/frames/frame1c.PNG";
import frame2 from "../images/doors/frames/frame2.PNG";
import frame2a from "../images/doors/frames/frame2a.PNG";
import frame2b from "../images/doors/frames/frame2b.PNG";
import frame2c from "../images/doors/frames/frame2c.PNG";

// TODO
export default class Frames extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Frames | TPX Doors & Windows</title>
          <meta
            name="description"
            content="There is no complete door set without a door frame. There are a lot
            of door frame types and choosing one, please consider that they may
            come in two options: rebated or non-rebated. For each offered door
            leaf we recommend a door frame which is suitable for the door you
            choose, both technically and aesthetically"
          />
        </Helmet>
        <HeaderDet height="70" hr="1" title="Frames" desc="" image={bg} />
        <div className="my-3 mx-3 item-details">
          <p className="mb-4" style={{ fontSize: "1.1rem" }}>
            There is no complete door set without a door frame. There are a lot
            of door frame types and choosing one, please consider that they may
            come in two options: rebated or non-rebated. For each offered door
            leaf we recommend a door frame which is suitable for the door you
            choose, both technically and aesthetically
          </p>
          <div className="row pt-4 item-container">
            <div className="col-md-4 mb-4">
              <img
                src={frame1}
                alt=""
                className=" d-block m-auto"
                style={{ height: "600px" }}
              />
            </div>
            <div className="col-md-8">
              <p>
                <b>1. Classic adjustable door frame system</b> ( with
                architraves 60 or 80mm) This system is easy to install and it's
                compatible with all internal door leaves. Thanks to the
                availability of the Retro Module you can achieve completely new
                and very different door styling.
              </p>
              <div className="row">
                <div className="col-md-12 col-xl-6">
                  <img src={frame1a} alt="" style={{ width: "100%" }} />
                </div>
                <div className="col-md-12 col-xl-6">
                  <img src={frame1b} alt="" style={{ width: "100%" }} />
                </div>
                <div className="col-md-6 col-xl-4">
                  <img src={frame1c} alt="" style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <img
                src={frame2}
                alt=""
                className=" d-block m-auto"
                style={{ height: "600px" }}
              />
            </div>
            <div className="col-md-8">
              <p className="text-justify">
                <b>2. Unique and innovate Adjustable door frame</b> with 80mm
                architraves. Non-rabated with an upper panel - 20 or 100cm high
              </p>
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <img src={frame2a} alt="" style={{ width: "100%" }} />
                </div>
                <div className="col-md-6 col-xl-4">
                  <img src={frame2b} alt="" style={{ width: "100%" }} />
                </div>
                <div className="col-md-6 col-xl-4">
                  <img src={frame2c} alt="" style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
