import React, { Component } from "react";
import HeaderDet from "./HeaderDet";
import erpol from "./images/erpol.png";
import valentino from "./images/valentino.png";
import bg from "./images/about/bg.jpg";
import { Helmet } from "react-helmet";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>About Us | TPX Doors & Windows</title>
          <meta
            name="description"
            content="TPX Doors&Windows Ltd. is an incorporated company established by 2
            founders who have 10 years of experience in constructions in the
            Lower Mainland."
          />
        </Helmet>
        <HeaderDet height="60" hr="1" title="About Us" desc="" image={bg} />

        <div className="container py-4 ">
          <p style={{ fontSize: "1.1rem", width: "90%" }} className="my-0">
            TPX Doors&Windows Ltd. is an incorporated company founded by 2
            partners who have 10 years of experience in constructions in the
            Lower Mainland.
          </p>
          <p className="my-0" style={{ fontSize: "1.1rem" }}>
            This exposure allowed us to understand that something new and
            special could be introduced to a local customer.
          </p>
          <p className="my-0" style={{ fontSize: "1.1rem" }}>
            Our business idea is to bring to you high innovations, top quality
            and customized solutions that are not seen on the Vancouver market.
          </p>
          <hr />
          <p className="my-1 fw-bold">We cooperate with:</p>
          <div className="row pt-4">
            <div
              className="col-md-6"
              style={{
                verticalAlign: "middle",
                display: "inline-block",
              }}
            >
              <a
                href="https://wleklik.wordpress.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={erpol}
                  alt=""
                  style={{
                    height: "96px",
                    display: "block",
                    margin: "auto",
                    marginTop: "1rem",
                  }}
                />
              </a>
            </div>
            <div className="col-md-6 position-relative">
              <a
                href="https://homesbyvalentino.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={valentino}
                  alt=""
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    margin: "auto",
                    marginTop: "1rem",
                  }}
                />
              </a>
            </div>
            <div className="col-md-3 col-0"></div>
            <div
              className="col-md-6 position-relative"
              style={{
                height: "130px",
                textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "130px",
                fontSize: "2.1rem",
                fontWeight: "bold",
              }}
            >
              <a
                href="https://pavilionventures.ca/"
                className="no-decoration"
                target="_blank"
                rel="noreferrer"
              >
                PAVILION VENTURE INC.
              </a>
            </div>
            <div className="col-md-3 col-0"></div>
          </div>
        </div>
      </div>
    );
  }
}
