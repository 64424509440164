import React, { Component } from "react";
import { Helmet } from "react-helmet";
import HeaderDet from "./HeaderDet";
import a404 from "./images/404.jpg";

export default class NotFound extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>404 | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="100"
          title="404"
          desc="The page you are looking for probably does not exist or has been moved. "
          btnText="GO BACK HOME"
          btnLink="/"
          image={a404}
        />
      </div>
    );
  }
}
