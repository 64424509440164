import React, { Component } from "react";
import HeaderDet from "./HeaderDet";
import { Link } from "react-router-dom";
import $ from "jquery";
import bg from "./images/about/bg.jpg";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const Contact = () => {
  // this.sendEmail = this.sendEmail.bind(this);
  const API_PATH = "../email.php";

  // const onSubmit = (data, e) => {
  //   // console.log(data);
  //   alert(
  //     "Name: " +
  //       data["firstName"] +
  //       " " +
  //       data["lastName"] +
  //       "\r\n" +
  //       "E-mail: " +
  //       data["email"] +
  //       "\r\n" +
  //       "Subject: " +
  //       data["subject"] +
  //       "\r\n" +
  //       "Message: " +
  //       data["message"]
  //   );

  function fileValidation(e) {
    var fileInput = document.getElementById("file");

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type. Try to use supported file formats.");
      fileInput.value = "";
      return false;
    } else {
      // Image preview
      if (fileInput.files && fileInput.files[0]) {
        var p = fileInput.files[0];
        if (p.size > 3000000) {
          alert("File too large.Try to use a photo smaller than 3MB");
          fileInput.value = "";
          return false;
        }
      }
    }
  }

  function sendEmail(e) {
    e.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: {
        "content-type": "application/json",
      },
    });
    console.log("sent");
    // emailjs
    //   .sendForm(
    //     "service_640ig7s",
    //     "template_0mkllqq",
    //     e.target,
    //     "user_55SbYmDkUybse7D0g5L0w"
    //   )
    //   .then(
    //     (result) => {
    //       alert("Your message has been sent. Thank you for contacting us!");
    //       window.location.reload();
    //     },
    //     (error) => {
    //       alert(
    //         "There was an internal error and we were unable to send your message. Please use the contact details below to contact us manually."
    //       );
    //     }
    //   );
  }

  // function send() {
  //   alert("Your message has been sent. Thank you for contacting us!");
  //   window.location.reload();
  // }

  // console.log(watch("firstName"));
  return (
    <div className="page">
      <Helmet>
        <title>Contact Us | TPX Doors & Windows</title>
        <meta name="description" content="Get in touch with our specialists." />
      </Helmet>
      <HeaderDet height="50" cls="d-none" btnLink="/contact" image={bg} />

      <div className="container">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <h5 className="fw-bold">TPX Doors&Windows Ltd.</h5>
            <p style={{ fontSize: "1.1rem" }}>
              552 22nd St W<br />
              North Vancouver, BC
              <br />
              V7M 2A5
            </p>
            <p className="m-0">
              <i
                className="fab fa-whatsapp-square fa-lg"
                style={{ color: "	#25D366" }}
              ></i>
               +1 (778) 682-8814
            </p>
            <p className="m-0">
              <i className="fas fa-phone-square fa-lg"></i>
               +1 (778) 682-8814
            </p>
            <p>
              <i className="fas fa-envelope-square fa-lg"></i> 
              <a href="mailto:contact@tpxdoors.ca" className="no-decoration">
                <u>contact@tpxdoors.ca</u>
              </a>
            </p>
            <br />
            {/* <h5 className="fw-bold">Address:</h5>
            <p className="m-0">Lorem, ipsum dolor.</p>
            <p className="m-0">Lorem ipsum dolor sit amet.</p> */}
          </div>
          <div className="col-lg-9 order-first order-lg-last mb-5">
            <form
              // onSubmit={sendEmail}
              id="contact"
              action="../email.php"
              method="POST"
              enctype="multipart/form-data"
            >
              <div className="input-group mb-3">
                <span className="input-group-text fw-bold">Name</span>
                <input
                  type="text"
                  className="form-control shadow-none"
                  placeholder=""
                  aria-label="Name"
                  id="firstName"
                  required
                  name="firstName"
                />
              </div>
              <div className="input-group mb-3">
                <span
                  data-tip="Optional field"
                  className="input-group-text fw-bold"
                  id="basic-addon1"
                >
                  Phone number‎ ‎
                  <span>
                    <i
                      className="far fa-question-circle"
                      style={{ color: "#00ba7c" }}
                    ></i>
                  </span>
                </span>
                <ReactTooltip />
                <input
                  type="tel"
                  className="form-control shadow-none"
                  placeholder=""
                  aria-label="Phone number"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </div>
              <div className="input-group mb-3">
                <label className="input-group-text fw-bold">
                  E-mail address
                </label>
                <input
                  type="email"
                  required
                  id="email"
                  className="form-control shadow-none"
                  placeholder=""
                  aria-label="E-mail"
                  name="email"
                />
              </div>
              <div className="input-group mb-3">
                <label className="input-group-text fw-bold">Subject</label>
                <select className="form-select" id="subject" name="subject">
                  <option defaultValue value="General">
                    General
                  </option>
                  <option value="Doors">Doors</option>
                  <option value="Windows">Windows</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <label className="input-group-text fw-bold">Message</label>
                <textarea
                  className="form-control shadow-none"
                  id="message"
                  name="message"
                  required
                  aria-label="With textarea"
                  style={{ minHeight: "150px" }}
                ></textarea>
              </div>
              <div className="input-group mb-3">
                <label
                  className="input-group-text fw-bold"
                  data-tip="Optional field"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Request a quote
                  <br />
                  Upload your drawings‎ ‎
                  <span>
                    <i
                      className="far fa-question-circle"
                      style={{ color: "#00ba7c", float: "right" }}
                    ></i>
                  </span>
                  <ReactTooltip />
                </label>
                <input
                  id="drawings"
                  style={{ height: "inherit" }}
                  // onChange={function () {
                  //   fileValidation();
                  // }}
                  accept=".png, .jpg, .jpeg, .gif"
                  name="drawings[]"
                  type="file"
                  className="form-control shadow-none"
                  multiple
                />
              </div>
              <div className="row">
                <div className="col-md-6">{/* CAPTCHA */}</div>
                <div className="col-md-6">
                  <button
                    id="liveAlertBtn"
                    // onClick={this.send}
                    className="btn btn-main shadow-none float-end"
                    type="submit"
                  >
                    SEND
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
