import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/residential/veneer_bg.jpg";
import nv1 from "../images/doors/residential/veneer/nv1.PNG";
import nv2 from "../images/doors/residential/veneer/nv2.PNG";
import nv3 from "../images/doors/residential/veneer/nv3.PNG";
import nv4 from "../images/doors/residential/veneer/nv4.PNG";
import nv5 from "../images/doors/residential/veneer/nv5.PNG";
import nv6 from "../images/doors/residential/veneer/nv6.PNG";
import nv7 from "../images/doors/residential/veneer/nv7.PNG";
import nv8 from "../images/doors/residential/veneer/nv8.PNG";
import nv9 from "../images/doors/residential/veneer/nv9.PNG";
import nv10 from "../images/doors/residential/veneer/nv10.PNG";
import nv11 from "../images/doors/residential/veneer/nv11.PNG";
import nv12 from "../images/doors/residential/veneer/nv12.PNG";
import nv13 from "../images/doors/residential/veneer/nv13.PNG";
import { Helmet } from "react-helmet";

export default class Veneer extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Natural Veneer | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Natural Veneer"
          desc=""
          image={bg}
        />
        <div className="my-3 mx-3 item-details">
          <p className="mb-5" style={{ fontSize: "1.1rem" }}>
            Natural veneers: Oak Ash, Walnut and Satin Oak - are characterized
            by a variety of colors, graining, etc. Their properties are the
            result of: natural environment of trees, climate, habitat
            conditions. These morphological properties of wood, which are
            characteristic for a natural material, distinguish it from synthetic
            finishes and aren’t defective in any way.
          </p>
          <div className="d-flex flex-wrap justify-content-center gap-3">
            <img src={nv1} alt="" style={{ width: "23%" }} />
            <img src={nv2} alt="" style={{ width: "23%" }} />
            <img src={nv3} alt="" style={{ width: "23%" }} />
            <img src={nv4} alt="" style={{ width: "23%" }} />
            <img src={nv5} alt="" style={{ width: "23%" }} />
            <img src={nv6} alt="" style={{ width: "23%" }} />
            <img src={nv7} alt="" style={{ width: "23%" }} />
            <img src={nv8} alt="" style={{ width: "23%" }} />
            <img src={nv9} alt="" style={{ width: "23%" }} />
            <img src={nv10} alt="" style={{ width: "23%" }} />
            <img src={nv11} alt="" style={{ width: "23%" }} />
            <img src={nv12} alt="" style={{ width: "23%" }} />
            <img src={nv13} alt="" style={{ width: "23%" }} />
          </div>
        </div>
      </div>
    );
  }
}
