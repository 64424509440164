import React, { Component } from "react";
import HeaderDet from "../HeaderDet";
import bg from "../images/doors/residential/double_bg.jpg";
import double1 from "../images/doors/residential/double1.jpg";
import double2 from "../images/doors/residential/double2.jpg";
// import double3 from "../images/doors/residential/double3.jpg";
import double4 from "../images/doors/residential/double4.jpg";
import { Helmet } from "react-helmet";

export default class DoubleDoors extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Double Doors | TPX Doors & Windows</title>
        </Helmet>
        <HeaderDet
          height="70"
          hr="1"
          title="Double doors, sliding and folding systems"
          desc=""
          image={bg}
          pos="right top"
        />
        <div className="my-3 mx-3 item-details">
          {/* <p className="fw-bold">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            dolorem non? Eaque voluptates ab consectetur iusto ducimus nesciunt,
            at cumque earum commodi sapiente accusamus voluptatibus ullam ad
            incidunt repudiandae corrupti quisquam molestias, porro deleniti?
            Repellendus et adipisci vel excepturi ducimus.
          </p> */}
          <div className="row pt-4 item-container">
            <div className="col-md-4">
              <img src={double1} alt="" className="product-img" />
            </div>
            <div className="col-md-8">
              <p
                className="fw-bold text-decoration-underline"
                style={{ fontSize: "1.1rem" }}
              >
                Double Doors
              </p>
              <p style={{ fontSize: "1.1rem" }}>
                Double doors with the active and inactive door leafs meeting at
                the rebate when closed. Non-standard glazing options are
                available and adapted to the non-standard size of the inactive
                door leaf.
              </p>
            </div>
            <div className="col-md-4">
              <img src={double2} alt="" className="product-img" />
            </div>
            <div className="col-md-8">
              <p
                className="fw-bold text-decoration-underline pt-3"
                style={{ fontSize: "1.1rem" }}
              >
                Wall mounted and in-wall sliding doors
              </p>
              <p style={{ fontSize: "1.1rem" }}>
                The sliding doors are non-rebated and available in all colours
                and models and in any size. Consult the selected door leaf model
                specifications. A standard sliding door leaf features a pull and
                a bottom milled slot to accommodate a stop for vertical door
                leaf stability.
              </p>
            </div>
            <div className="col-md-4">
              <img src={double4} alt="" className="product-img" />
            </div>
            <div className="col-md-8">
              <p
                className="fw-bold text-decoration-underline pt-3"
                style={{ fontSize: "1.1rem" }}
              >
                Folding doors
              </p>
              <p style={{ fontSize: "1.1rem" }}>
                The folding doors are available with solid single leafs or
                double solid leafs. Both folding halves of the door are bonded
                with 3 hinges. The door is attached to a floor-mounted sta-
                bilizer track. The folding mechanism is installed in the header
                of the timber frame. This system is recommended anywhere there
                is no room for standard swing or sliding doors.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
